<template>
  <b-container class="mt-3">
    <h2>Subcuentas</h2>

    <section>
      <article class="d-flex align-items-center" style="column-gap: 1rem;">
        <select-cliente @select-cliente="value => clienteSelected = value" v-if="soyAdmin" />
        <b-form-input placeholder="Buscar..." v-model="busqueda"></b-form-input>
        <b-button variant="primary" v-b-modal.modal-agregar-subcuenta :disabled="loading"
          class="d-flex align-items-center">
          <b-spinner v-if="loading" small></b-spinner>
          {{ loading ? 'Cargando' : 'Agregar' }}
        </b-button>
      </article>

      <article>
        <b-overlay rounded="sm" :show="loading">
          <b-table-simple bordered small>
            <b-thead>
              <b-tr>
                <b-th>Correo</b-th>
                <b-th>Teléfono</b-th>
                <b-th>Ciudad</b-th>
                <b-th>Domicilio</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="subcuenta, index of filtro" :key="index">
                <b-td>{{ subcuenta.email }}</b-td>
                <b-td>{{ subcuenta.celular }}</b-td>
                <b-td>{{ subcuenta.ciudad }}</b-td>
                <b-td>{{ subcuenta.domicilio }}</b-td>
                <b-td class="d-flex justify-content-around align-items-center">
                  <b-icon icon="trash" @click="deleteSubcuenta(subcuenta)" style="cursor: pointer;"></b-icon>
                  <b-icon icon="pencil" @click="editarCuenta(subcuenta)" style="cursor: pointer;"></b-icon>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
      </article>
    </section>

    <b-modal id="modal-agregar-subcuenta" :title="edicion ? 'Editar Subcuenta' : 'Agregar Subcuenta'" ok-title="Guardar"
      cancel-title="Cancelar" size="xl" no-close-on-esc no-close-on-backdrop headerBgVariant="dark"
      headerTextVariant="light" bodyBgVariant="light" bodyTextVariant="dark" @hidden="resetModal" @ok="guardarCuenta">
      <b-overlay rounded="sm" :show="loading">
        <b-container fluid>
          <div class="row mb-3">
            <div class="col">
              <label for="email">Email</label>
              <b-input id="email" placeholder="example@example.com" v-model="nuevaSubcuenta.email"></b-input>
            </div>

            <div class="col" v-if="!edicion">
              <label for="password">Password</label>
              <b-input id="password" placeholder="******" type="password" v-model="nuevaSubcuenta.password"></b-input>
            </div>

            <div class="col">
              <label for="celular">Celular</label>
              <b-input id="celular" placeholder="6678574859" v-model="nuevaSubcuenta.celular"></b-input>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label for="nombre">Nombre</label>
              <b-input id="nombre" placeholder="John" v-model="nuevaSubcuenta.nombre"></b-input>
            </div>

            <div class="col">
              <label for="apellido">Apellido</label>
              <b-input id="apellido" placeholder="Dafoe" v-model="nuevaSubcuenta.apellido"></b-input>
            </div>

            <div class="col">
              <label for="curp">CURP</label>
              <b-input id="curp" placeholder="CURP" v-model="nuevaSubcuenta.curp"></b-input>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label for="no_seguro">No. Seguro</label>
              <b-input id="no_seguro" placeholder="123456789" v-model="nuevaSubcuenta.no_seguro"></b-input>
            </div>

            <div class="col">
              <label for="email_personal">Email Personal</label>
              <b-input id="email_personal" placeholder="example@example.com"
                v-model="nuevaSubcuenta.email_personal"></b-input>
            </div>

            <div class="col">
              <label for="ciudad">Ciudad</label>
              <b-input id="ciudad" placeholder="Culiacan" v-model="nuevaSubcuenta.ciudad"></b-input>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label for="estadosCuenta">Estados de cuenta</label>
              <b-form-checkbox id="estadosCuenta" v-model="nuevaSubcuenta.estadosCuenta" name="checkbox-1" :value="true"
                :unchecked-value="false">
                Acceso a Estados de cuenta
              </b-form-checkbox>
            </div>

            <div class="col">
              <label for="listasPrecios">Listas de Precios</label>
              <b-form-checkbox id="listasPrecios" v-model="nuevaSubcuenta.listasPrecios" name="checkbox-1" :value="true"
                :unchecked-value="false">
                Acceso a Listas de precios
              </b-form-checkbox>
            </div>
          </div>

          <label for="domicilio">Domicilio</label>
          <b-textarea id="domicilio" placeholder="Calle, CP, Numero Exterior"
            v-model="nuevaSubcuenta.domicilio"></b-textarea>
        </b-container>
      </b-overlay>
    </b-modal>
  </b-container>
</template>

<script>
import Swal from 'sweetalert2';
import SelectCliente from '../../components/base/_BaseSelectCliente.vue'
import { axiosInstance } from "../../utils/axiosInstance";
import { emailRegex } from '../../utils/regex';
import { AccessCodes } from '../../enums/index'
export default {
  data() {
    return {
      busqueda: '',
      subcuentas: [],
      nuevaSubcuenta: {
        usuario_id: '',
        email: '',
        password: '',
        nombre: '',
        apellido: '',
        celular: '',
        curp: '',
        no_seguro: '',
        email_personal: '',
        ciudad: '',
        domicilio: '',
        estadosCuenta: false,
        listasPrecios: false,
      },
      edicion: false,
      loading: false,
      clienteSelected: null,
    }
  },
  computed: {
    filtro() {
      return this.subcuentas.filter(cuenta => !this.busqueda ||
        cuenta.email.toLowerCase().includes(this.busqueda.toLowerCase()) ||
        cuenta.celular.toLowerCase().includes(this.busqueda.toLowerCase()) ||
        cuenta.estado.toLowerCase().includes(this.busqueda.toLowerCase()) ||
        cuenta.ciudad.toLowerCase().includes(this.busqueda.toLowerCase()) ||
        cuenta.domicilio.toLowerCase().includes(this.busqueda.toLowerCase())
      )
    },
    soyAdmin() {
      return sessionStorage.getItem('admin') === 'true'
    }
  },
  methods: {
    async deleteSubcuenta(subcuenta) {
      if (this.soyAdmin && !this.clienteSelected) {
        this.makeToast('Error', 'Debe seleccionar un cliente para esta accion', 'warning', 3000);
        return;
      }

      const result = await Swal.fire({
        title: "¿Estás seguro? Esta acción es irreversible.",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: 'Cancelar'
      })

      if (!result.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await axiosInstance.delete(`usuarios/cuenta-hija/${this.soyAdmin ? this.clienteSelected : localStorage.getItem('usuario_id')}/${subcuenta.usuario_id}`, {
          headers: {
            authorization: localStorage.getItem('token')
          }
        })
        this.getSubcuentas();
        this.makeToast('Actualizada', 'Subcuenta eliminada', 'success', 3000);
      } catch (error) {
        this.makeToast('Error', error.response.data.msg || 'Hubo un error al eliminar la subcuenta', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
    resetModal() {
      this.edicion = false;
      this.nuevaSubcuenta = {
        usuario_id: '',
        email: '',
        password: '',
        nombre: '',
        apellido: '',
        celular: '',
        curp: '',
        no_seguro: '',
        email_personal: '',
        ciudad: '',
        domicilio: ''
      };
    },
    async guardarCuenta(bvModalEvent, usuario_id = '') {
      bvModalEvent.preventDefault();

      if (this.soyAdmin && !this.clienteSelected) {
        this.makeToast('Error', 'Debe seleccionar un cliente para esta accion', 'warning', 3000);
        return;
      }

      this.nuevaSubcuenta.email = this.nuevaSubcuenta.email.trim();
      this.nuevaSubcuenta.password = this.nuevaSubcuenta.password.trim();
      this.nuevaSubcuenta.nombre = this.nuevaSubcuenta.nombre.trim();
      this.nuevaSubcuenta.apellido = this.nuevaSubcuenta.apellido.trim();
      this.nuevaSubcuenta.celular = this.nuevaSubcuenta.celular.trim();
      this.nuevaSubcuenta.curp = this.nuevaSubcuenta.curp.trim();
      this.nuevaSubcuenta.no_seguro = this.nuevaSubcuenta.no_seguro.trim();
      this.nuevaSubcuenta.email_personal = this.nuevaSubcuenta.email_personal.trim();
      this.nuevaSubcuenta.ciudad = this.nuevaSubcuenta.ciudad.trim();
      this.nuevaSubcuenta.domicilio = this.nuevaSubcuenta.domicilio.trim();

      if (!this.nuevaSubcuenta.email ||
        !this.nuevaSubcuenta.password ||
        !this.nuevaSubcuenta.nombre ||
        !this.nuevaSubcuenta.apellido ||
        !this.nuevaSubcuenta.celular ||
        !this.nuevaSubcuenta.curp ||
        !this.nuevaSubcuenta.no_seguro ||
        !this.nuevaSubcuenta.email_personal ||
        !this.nuevaSubcuenta.ciudad ||
        !this.nuevaSubcuenta.domicilio
      ) {
        this.makeToast('Datos faltantes', 'Todos los datos son obligatorios.', 'warning', 3000);
        return;
      }

      if (!emailRegex(this.nuevaSubcuenta.email) || !emailRegex(this.nuevaSubcuenta.email_personal)) {
        this.makeToast('Datos inválidos', 'Los email deben de ser válidos.', 'warning', 3000);
        return;
      }

      if (this.nuevaSubcuenta.celular.length != 10) {
        this.makeToast('Datos inválidos', 'Celular deben de ser 10 caracteres.', 'warning', 3000);
        return;
      }

      if (isNaN(parseInt(this.nuevaSubcuenta.celular))) {
        this.makeToast('Datos inválidos', 'Celular deben de ser únicamente números.', 'warning', 3000);
        return;
      }

      this.loading = true;

      if (this.edicion) {
        try {
          await axiosInstance.put(`usuarios/cuenta-hija/${usuario_id || localStorage.getItem('usuario_id')}`, {
            cuenta: this.nuevaSubcuenta
          }, {
            headers: {
              authorization: localStorage.getItem('token')
            }
          });
          this.getSubcuentas();
          this.makeToast('Actualizada', 'Subcuenta actualizada.', 'success', 3000);
          this.$bvModal.hide('modal-agregar-subcuenta');
        } catch (error) {
          this.makeToast('Error', error.response.data.msg || 'Hubo un error al actualizar la subcuenta.', 'danger', 3000);
        } finally {
          this.loading = false;
        }
      }
      else {
        try {
          await axiosInstance.post(`usuarios/cuenta-hija/${usuario_id || localStorage.getItem('usuario_id')}`, {
            Usuario: this.nuevaSubcuenta
          }, {
            headers: {
              authorization: localStorage.getItem('token')
            }
          });
          this.getSubcuentas();
          this.makeToast('Guardada', 'Subcuenta creada', 'success', 3000);
          this.$bvModal.hide('modal-agregar-subcuenta');
        } catch (error) {
          this.makeToast('Error', error.response.data.msg || 'Hubo un error al crear la subcuenta', 'danger', 3000);
        } finally {
          this.loading = false
        }
      }
    },
    editarCuenta(subcuenta) {
      this.edicion = true;
      this.nuevaSubcuenta = {
        ...subcuenta
      }
      this.$bvModal.show('modal-agregar-subcuenta');
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async getSubcuentas(usuario_id = '') {
      this.loading = true;
      try {
        this.subcuentas = (await axiosInstance.get(`usuarios/cuentas-hijas/${usuario_id || localStorage.getItem('usuario_id')}`, {
          headers: {
            authorization: localStorage.getItem('token')
          }
        })).data;

        this.subcuentas.forEach(cuenta => {
          cuenta.estadosCuenta = Boolean(cuenta.usuario_accesos.find(x => x.AccesoCode === AccessCodes.ESTADOS_CUENTA));
          cuenta.listasPrecios = Boolean(cuenta.usuario_accesos.find(x => x.AccesoCode === AccessCodes.LISTAS_PRECIO));
        });

      } catch (error) {
        this.makeToast('Subcuentas', error.response.data.msg || 'Hubo un error al consultar las subcuentas', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (!this.soyAdmin) {
      this.getSubcuentas();
    }
  },
  components: {
    SelectCliente
  },
  watch: {
    clienteSelected(newVal) {
      if (!newVal) {
        return;
      }

      this.getSubcuentas(newVal)
    }
  }
}
</script>